@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

body {
  font-family: "Open Sans", sans-serif;
}

#wrapper {
  margin: 8px;
  font-family: "Open Sans", sans-serif;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 36px;
  border-bottom: 2px solid #ff7a00;
  margin-bottom: 25px;
  display: table;
}

h1:after {
  content: "";
  display: block;
  border: 2px solid #ff9533;
  width: 100px;
}

h3 {
  font-size: 26px;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #ff7a00;
  margin-bottom: 15px;
  display: table;
}

h3:after {
  content: "";
  display: block;
  border: 2px solid #ff9533;
  width: 100px;
}

p.question {
  font-weight: 600;
}

p.answer {
  margin-left: 10px;
}

.fa-blue {
  color: royalblue;
}

.fa-green {
  color: green;
}

.fa-red {
  color: red;
}

.fa-h2 {
  color: orangered;
  padding-right: 1.5em;
}

/* Breadcrumbs */
section#breadcrumb {
  background: black;
  margin-bottom: 30px;
}

#breadcrumb .breadcrumb {
  margin: 0;
  background: none;
  padding: 0.25rem 0rem;
  font-size: 12px;
}

.breadcrumb li {
  color: white;
}

.breadcrumb li a {
  color: white;
}

.breadcrumb li a:hover {
  color: lightgray;
}

.breadcrumb-item {
  color: white;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  color: white !important;
}

/* Quote */
blockquote {
  border-radius: 3px;
  position: relative;
  font-style: italic;
  text-align: center;
  padding: 1rem 1.2rem;
  width: 70%;
  color: white;
  margin: 1rem auto 2rem;
  background: whitesmoke;
}

/* quote */
.quote {
  div.text {
    border-radius: 3px;
    position: relative;
    font-style: italic;
    text-align: center;
    padding: 1rem 1.2rem;
    width: 70%;
    color: white;
    margin: 1rem auto 2rem;
    background: whitesmoke;
  }
  p.title {
    color: #099cdb;
    font-size: 18px;
    font-weight: bold;
  }

  p.author {
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
  p.author::before {
    content: "\2014\00A0";
  }

  p.leftquote {
    color: #ff9533;
    text-align: right;
    margin-top: 2rem;
  }
  p.rightquote {
    color: #ff9533;
    text-align: left;
    margin-top: 2rem;
  }
}

/* -- Tables -- */
.table-title {
  background-color: whitesmoke;
  padding: 20px;
}

.table-title .btn {
  float: right;
}

.table-title p {
  margin: auto;
  font-size: 24px;
}

.table i {
  margin: 0 5px;
}

/* --- Page headers --- */
.app-page-title {
  background-color: #34aadd;
  margin-top: -30px;
  margin-bottom: 30px;
}

.app-page-title-icon-container {
  background-color: white;
  padding: 5px;
  margin: 10px;
  color: #34aadd;
}

.app-page-title-text {
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.app-page-title-subtext {
  color: whitesmoke;
  font-size: 14px;
  font-style: italic;
  margin: 0;
}
