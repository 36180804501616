/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: #34aadd;
  padding: 0 0 30px 0;
  color: white;
  font-size: 14px;
  margin-top: 30px;
  padding: 20px 0 10px 0;
}

#footer .no-margin-bottom {
  margin-bottom: 0;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #007bff;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #0067d5;
  color: #fff;
}

#footer h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 34px;
  font-weight: 700;
  padding-bottom: 10px;
}

#footer h4 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 10px;
}

/* Footer links */
#footer .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-links ul a {
  color: white;
}

#footer .footer-links ul a:hover {
  color: lightgray;
}

#footer .copyright {
  text-align: center;
  padding-top: 30px;
}
